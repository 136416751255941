import { css } from 'astroturf';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import PageHeader from './PageHeader';
import Link from './link';

import Mission from '../../assets/images/mission.svg';

const styles = css`
  .bg {
    position: relative;
    padding: 0rem 0 0rem;
  }

  .image {
    margin: 0 auto 1rem;
    border-radius: 100px;
  }
  .cta {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    padding: 0rem 2rem 0;
    color: #000;

    @media (min-width: 30rem) {
      padding: 0rem 4rem 0;
    }

    & * + * {
      margin-top: 2rem;
    }
    & h3 {
      font-weight: 600;
      color: #000;
      font-size: calc(16px + 0.6vw);
    }
  }
`;

export default () => (
  <>
    <PageHeader>
      <Mission style={{ maxWidth: '100%' }} />
      <h1>Polypane's mission</h1>
    </PageHeader>
    <div className={styles.bg}>
      <div className={styles.cta}>
        <div>
          <p>
            Our mission is to be the best browser to develop and test websites and web applications in. We believe
            developers spend way too much time managing their browsers and waiting for tests to run. Polypane takes away
            all that managing and waiting so you can spend more time on your craft and polishing the websites you build.
          </p>

          <p>
            Whether you're building a mobile web experience or a regular website, the tools in Polypane help you develop
            responsive and accessible sites without resizing your browser all the time and let you inspect your site's
            quality without needing to make your site publicly accessible and waiting on third party tests to run.
          </p>

          <p>
            With Polypane, we want to be <i>prescriptive</i>, not <i>descriptive</i>. What we mean with that is that
            while there are plenty of tools that will tell you what your site is doing wrong, few of them will tell you{' '}
            <strong>what to do about it</strong>. Polypane tells you what to improve and how to improve it, so you can
            get on with actually developing the site.
          </p>
        </div>
      </div>
    </div>

    <PageHeader>
      <StaticImage
        src="../../assets/images/kilian.jpg"
        className={styles.image}
        alt="Kilian"
        title="Kilian"
        width={110}
      />
      <h2 className="h1" style={{ fontSize: 32 }}>
        Hi! 👋 I'm Kilian.
      </h2>
      <h3 className="h2">
        I'm the founder, developer, designer and support team at Polypane.
        <br /> Nice to meet you!
      </h3>
    </PageHeader>
    <div className={styles.bg}>
      <div className={styles.cta}>
        <div>
          <p>
            I'm a web developer from The Netherlands 🇳🇱, where I've been designing and building sites for the web for
            nearly 20 years (&hellip;I got started early).
          </p>
          <p>
            I had a prototype of Polypane that I used for a couple of years at my web agency, after coming up with it as
            a solution to getting super annoyed having to resize my browser all the time. As I used it and kept adding
            features I noticed that it was making me so much faster (like, 3 times faster 🤯).
          </p>
          <p>
            So I switched from building websites to building a browser. Polypane is my full-time job and I'm doing
            everything I can to make it the perfect browser for developers and designers. For the complete history, read{' '}
            <Link to="https://kilianvalkhof.com/2020/design/how-i-built-my-own-browser/">
              How I built my own browser
            </Link>
          </p>

          <p>
            Beyond Polypane, I also develop <Link to="https://superposition.design">Superposition</Link>, which extracts
            design tokens from live websites and then exports them to code and to design tools and{' '}
            <Link to="https://fromscratch.rocks">FromScratch</Link>, an autosaving scratchpad that I use all day. I also
            do a ton of <Link to="https://github.com/kilian/">open source</Link> work, I'm part of the{' '}
            <Link to="https://electronjs.org">Electron</Link> governance team and if you write JavaScript in any form,
            you probably have <Link to="https://www.npmjs.com/package/electron-to-chromium">code I wrote</Link> on your
            computer right now.
          </p>
          <p>
            I'd love to hear from you. Connect with me via <Link to="https://twitter.com/kilianvalkhof">Twitter</Link>,{' '}
            <Link to="https://www.linkedin.com/in/kilianvalkhof/">LinkedIn</Link> or{' '}
            <Link to="mailto:kilian@polypane.app">email</Link>.
          </p>
        </div>
      </div>
    </div>
  </>
);
