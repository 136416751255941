import React, { Component } from 'react';
import CTA from '../components/CTA';
import Kilian from '../components/Kilian';
import SEO from '../components/SEO';
import Layout from '../layout';

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO title="About" keywords={['']} pathname={this.props.location.pathname} />
        <Kilian />
        <div style={{ marginTop: '8rem' }}></div>
        <CTA />
        <div style={{ marginTop: '8rem' }}></div>
      </Layout>
    );
  }
}

export default Page;
